export const getContacts = /* GraphQL */ `
 query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String,
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        first_name
        last_name
        primary_email
        updatedAt
        createdAt
        state
        zip_code
        vip
        vip_type
        company {
          id
          name
        }
      }
    }
  }
`;

export const getContactsWithAssignedTo = /* GraphQL */ `
 query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String,
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        first_name
        last_name
        primary_email
        updatedAt
        createdAt
        state
        zip_code
        vip
        vip_type
        company {
          id
          name
        }
        assignees {
          items {
            id
            employee {
              id
              name
            }
          }
        }
        groups {
          items {
            id
            group {
              id
              name
              type
            }
          }
        }
        divisions {
          items {
            id
            division {
              id
              label
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getContactsWithAssignedToJustIds = /* GraphQL */ `
 query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String,
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        assignees {
          items {
            id
            employee {
              id
              name
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getContactWithAssignees = /* GraphQL */ `
  query GetContact(
    $id: ID!
  ) {
    getContact(id: $id) {
      id
      assignees {
        items {
          id
          employee {
            id
            name
          }
        }
      }
    }
  }
`;

export const getContactWithDivisions = /* GraphQL */ `
  query GetContact(
    $id: ID!
  ) {
    getContact(id: $id) {
      id
      divisions {
        items {
          id
          division {
            id
            label
          }
        }
      }
    }
  }
`;

export const getContactsWithGroupContactsDetailed = /* GraphQL */ `
 query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String,
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        first_name
        last_name
        primary_email
        updatedAt
        createdAt
        state
        zip_code
        vip
        vip_type
        company {
          id
          name
        }
        groups {
          items {
            id
            group {
              id
              name
              type
            }
          }
        }
        divisions {
          items {
            id
            division {
              id
              label
            }
          }
        }
      }
      nextToken
    }
  }
`;

// List of contacts with group contacts
export const getContactsWithGroupContacts = /* GraphQL */ `
 query ListContactsByFirstName(
    $type: String
    $first_name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactsByFirstName(
      type: $type
      first_name: $first_name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        last_name
        first_name
        groups {
          items {
            id
          }
        }
      }
    }
  }
`;

// Get one contact with group contacts
export const getContactWithGroupContacts = /* GraphQL */ `
 query GetContact(
    $id: ID!
  ) {
    getContact(
      id: $id
    ) {
      id
      last_name
      first_name
      groups {
        items {
          id
        }
      }
    }
  }
`;

export const getContactsWithGroups = /* GraphQL */ `
 query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        last_name
        first_name
        groups {
          items {
            id
            group {
              id
              name
              type
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getContactsByMailchimpIdWithGroups = /* GraphQL */ `
query ListContactsByMailchimpId(
    $mailchimp_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactsByMailchimpId(
      mailchimp_id: $mailchimp_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        last_name
        first_name
        groups {
          items {
            id
            group {
              id
              mailchimp_list_id
            }
          }
        }
      }
    }
  }
`;

export const getContactsByFirstName = /* GraphQL */ `
  query ListContactsByFirstName(
    $type: String
    $first_name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactsByFirstName(
      type: $type
      first_name: $first_name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        first_name
        last_name
        primary_email
        state
        zip_code
        vip
        vip_type
        company {
          id
          name
        }
        groups {
          items {
            id
            group {
              id
              name
              type
            }
          }
        }
        divisions {
          items {
            id
            division {
              id
              label
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getContactsByLastName = /* GraphQL */ `
  query ListContactsByLastName(
    $type: String
    $last_name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactsByLastName(
      type: $type
      last_name: $last_name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        first_name
        last_name
        primary_email
        state
        zip_code
        vip
        vip_type
        company {
          id
          name
        }
        groups {
          items {
            id
            group {
              id
              name
              type
            }
          }
        }
        divisions {
          items {
            id
            division {
              id
              label
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getContactsByCreatedAt = /* GraphQL */ `
  query ListContactsByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactsByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        first_name
        last_name
        primary_email
        state
        zip_code
        vip
        vip_type
        company {
          id
          name
        }
        groups {
          items {
            id
            group {
              id
              name
              type
            }
          }
        }
        divisions {
          items {
            id
            division {
              id
              label
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getContactsByUpdatedAt = /* GraphQL */ `
  query ListContactsByUpdatedAt(
    $type: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactsByUpdatedAt(
      type: $type
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        first_name
        last_name
        primary_email
        state
        zip_code
        vip
        vip_type
        company {
          id
          name
        }
        groups {
          items {
            id
            group {
              id
              name
              type
            }
          }
        }
        divisions {
          items {
            id
            division {
              id
              label
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getContactsTotal = /* GraphQL */ `
 query ListContacts(
    $limit: Int
    $nextToken: String
  ) {
    listContacts(
      limit: $limit
      nextToken: $nextToken
  ) {
      items {
        id
      }
      nextToken
    }
  }
`;

// export const getContactsWithNameAndEmail = /* GraphQL */ `
//  query ListContacts(
//     $limit: Int
//   ) {
//     listContacts(limit: $limit) {
//       items {
//         id
//         first_name
//         last_name
//         primary_email
//       }
//     }
//   }
// `;

export const searchContactsByNameOrCompany = /* GraphQL */ `
  query ListContacts(
    $filterContact: ModelContactFilterInput
    $filterCompany: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filterContact, limit: $limit, nextToken: $nextToken) {
      items {
        id
        first_name
        last_name
        primary_email
        primary_phone
      }
    }
    listCompanys(filter: $filterCompany, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        contacts {
          items {
            id
            first_name
            last_name
            primary_email
            primary_phone
          }
        }
      }
    }
  }
`;

export const editContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      mailchimp_id
    }
  }
`;

export const searchContactsCustom = /* GraphQL */ `
  query SearchContacts(
    $filter: SearchableContactFilterInput
    $sort: SearchableContactSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchContacts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        first_name
        last_name
        full_name
        primary_email
        state
        zip_code
        vip
        vip_type
        company {
          id
          name
        }
        groups {
          items {
            id
            group {
              id
              name
              type
            }
          }
        }
        assignees {
          items {
            id
            employee {
              id
              name
            }
          }
        }
        divisions {
          items {
            id
            division {
              id
              label
            }
          }
        }
      }
      nextToken
      total
    }
  }
`;

export const searchContactsList = /* GraphQL */ `
  query SearchContacts(
    $filter: SearchableContactFilterInput
    $sort: SearchableContactSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchContacts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        full_name
        primary_email
        company {
          name
        }
      }
      nextToken
      total
    }
  }
`;

export const getContactsWithNameAndEmail = /* GraphQL */ `
  query ListContactsByFirstName(
    $type: String
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listContactsByFirstName(
      type: $type
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        first_name
        last_name
        full_name
        primary_email
        company {
          name
        }
      }
      nextToken
    }
  }
`;
