export const filterGroupContactsById = /* GraphQL */ `
  query ListGroupContacts(
    $filter: ModelGroupContactFilterInput
  ) {
    listGroupContacts(filter: $filter) {
      items {
        id
        contact {
          id
        }
      }
    }
  }
`;

export const onDeleteGroupContactSlim = /* GraphQL */ `
  subscription OnDeleteGroupContact {
    onDeleteGroupContact {
      id
      mailchimp_status
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        mailchimp_id
      }
      group {
        id
        name
        mailchimp_list_id
        mailchimp_webhook_id
        mailchimp_tag_id
        mailchimp_tag_name
        mailchimp_newsletter_id
        type
      }
    }
  }
`;
