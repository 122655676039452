export const getTasksByEmployee = /* GraphQL */ `
  query ListTasksByAssignedTo(
    $status: TaskStatus
    $employeeIdDue_date: ModelTaskByAssignedToCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByAssignedTo(
      status: $status
      employeeIdDue_date: $employeeIdDue_date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        due_date
        description
        linkType
        creatorId
        employeeId
        assignedTo {
          id
          name
          }
        status
        createdAt
        updatedAt
        contact {
          id
          type
          full_name
        }
        company {
          id
          type
          name
        }
      }
      nextToken
    }
  }
`;


export const getTasksByDueDate = /* GraphQL */ `
  query ListTasksByDueDate(
    $status: TaskStatus
    $due_date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByDueDate(
      status: $status
      due_date: $due_date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        due_date
        description
        linkType
        creatorId
        employeeId
        assignedTo {
          id
          name 
        }
        status
        createdAt
        updatedAt
        contact {
          id
          type
          full_name
        }
        company {
          id
          type
          name
        }
      }
      nextToken
    }
  }
`;