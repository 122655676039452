export const getGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        mailchimp_list_id
        mailchimp_webhook_id
        type
      }
    }
  }
`;

export const getGroupsWithContacts = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limitGroups: Int
    $limitContacts: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limitGroups, nextToken: $nextToken) {
      items {
        id
        name
        contacts(limit: $limitContacts) {
          items {
            contact {
              id
              first_name
              last_name
              primary_email
              updatedAt
              createdAt
              state
              zip_code
              company {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const getGroupsWithCompanys = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limitGroups: Int
    $limitCompanys: Int
    $limitContacts: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limitGroups, nextToken: $nextToken) {
      items {
        id
        name
        companies(limit: $limitCompanys) {
          items {
            company {
              id
              name
              vip
              city
              state
              zip_code
              createdAt
              updatedAt
              contacts(limit: $limitContacts) {
                items {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getGroupWithContacts = /* GraphQL */ `
  query GetGroup(
    $id: ID!
    $limitContacts: Int
    $nextTokenContacts: String
  ) {
    getGroup(id: $id) {
      id
      name
      contacts(limit: $limitContacts, nextToken: $nextTokenContacts) {
        items {
          contact {
            id
            first_name
            last_name
            primary_email
            updatedAt
            createdAt
            state
            zip_code
            company {
              id
              name
            }
            groups {
              items {
                id
                group {
                  id
                  name
                  type
                }
              }
            }
          }
        }
        nextToken
      }
    }
  }
`;

export const getGroupWithContactsLean = /* GraphQL */ `
  query GetGroup(
    $id: ID!
    $limitContacts: Int
    $nextTokenContacts: String
  ) {
    getGroup(id: $id) {
      id
      name
      contacts(limit: $limitContacts, nextToken: $nextTokenContacts) {
        items {
          contact {
            id
            first_name
            last_name
            primary_email
            updatedAt
            createdAt
            state
            zip_code
            groups {
              items {
                id
                group {
                  id
                  name
                  type
                }
              }
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const getGroupWithTotalContacts = /* GraphQL */ `
  query GetGroup(
    $id: ID!
    $limitContacts: Int
    $nextTokenContacts: String
  ) {
    getGroup(id: $id) {
      id
      name
      contacts(limit: $limitContacts, nextToken: $nextTokenContacts) {
        items {
          contact {
            id
          }
        }
        nextToken
      }
    }
  }
`;

export const getGroupWithCompanys = /* GraphQL */ `
  query GetGroup(
    $id: ID!
    $limitCompanys: Int
    $limitContacts: Int
    $nextTokenCompanys: String
  ) {
    getGroup(id: $id) {
      id
      name
      companies(limit: $limitCompanys, nextToken: $nextTokenCompanys) {
        items {
          company {
            id
            name
            vip
            city
            state
            zip_code
            createdAt
            updatedAt
            contacts(limit: $limitContacts) {
              items {
                id
              }
            }
            groups {
              items {
                id
                group {
                  id
                  name
                  type
                }
              }
            }
          }
        }
        nextToken
      }
    }
  }
`;