export const getPageWithSortedCollections = /* GraphQL */ `
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
      name
      collections (sortDirection: ASC, limit: 1000) {
        items {
          id
          name
          index
          collectionPageId
        }
        nextToken
      }
    }
  }
`;
