export const onDeleteGroupCompanySlim = /* GraphQL */ `
  subscription OnDeleteGroupCompany {
    onDeleteGroupCompany {
      id
      mailchimp_status
      createdAt
      updatedAt
      company {
        id
        type
        name
        email
        mailchimp_id
      }
      group {
        id
        name
        mailchimp_list_id
        mailchimp_webhook_id
        mailchimp_tag_id
        mailchimp_tag_name
        mailchimp_newsletter_id
        type
      }
    }
  }
`;

