export const getCalendarsByUser = /* GraphQL */ `
  query ListCalendarsByUser(
    $calendarUserId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendarsByUser(
      calendarUserId: $calendarUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        primary
        calendarUserId
        color
        user {
          id
          name
          jobTitle
          email
        }
        googleCalendarId
        googleChannelId
        googleResourceId
        googleSyncToken
        googleName
        permissions {
          items {
            id
            type
            rule
            viewActive
            createdAt
            updatedAt
            user {
              id
              email
              name
              jobTitle
            }
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;