export const getEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        status
        jobTitle
      }
      nextToken
    }
  }
`;

export const getEmployeeWithContacts = /* GraphQL */ `
  query GetEmployee(
    $id: ID!
    $contactLimit: Int
    $contactNextToken: String
  ) {
    getEmployee(id: $id) {
      id
      name
      assignedContacts(limit: $contactLimit, nextToken: $contactNextToken) {
        items {
          id
          contact {
            id
            first_name
            last_name
            primary_email
            state
            zip_code
            vip
            vip_type
            company {
              id
              name
            }
            groups {
              items {
                id
                group {
                  id
                  name
                  type
                }
              }
            }
            divisions {
              items {
                id
                division {
                  id
                  label
                }
              }
            }
          }
        }
        nextToken
      }
    }
  }
`;

export const getEmployeeWithCompanies = /* GraphQL */ `
  query GetEmployee(
    $id: ID!
    $companyLimit: Int
    $companyNextToken: String
  ) {
    getEmployee(id: $id) {
      id
      name
      assignedCompanies(limit: $companyLimit, nextToken: $companyNextToken) {
        items {
          id
          company {
            id
            name
            vip
            vip_type
            city
            state
            zip_code
            createdAt
            updatedAt
            assignedTo {
              id
              name
            }
            contacts {
              items {
                id
              }
            }
            groups {
              items {
                id
                group {
                  id
                  name
                  type
                }
              }
            }
            divisions {
              items {
                id
                division {
                  id
                  label
                }
              }
            }
          }
        }
        nextToken
      }
    }
  }
`;

export const getEmployeeWithContactsForExport = /* GraphQL */ `
  query GetEmployee(
    $id: ID!
    $limit: Int
    $nextToken: String
  ) {
    getEmployee(id: $id) {
      id
      name
      assignedContacts(limit: $limit, nextToken: $nextToken) {
        items {
          id
          contact {
            first_name
            last_name
            primary_email
            updatedAt
            createdAt
            state
            zip_code
            vip_type
            assignedTo {
              name
            }
            company {
              name
            }
          }
        }
        nextToken
      }
    }
  }
`;

export const getEmployeeWithCompanysForExport = /* GraphQL */ `
  query GetEmployee(
    $id: ID!
    $limit: Int
    $nextToken: String
  ) {
    getEmployee(id: $id) {
      id
      name
      assignedCompanies(limit: $limit, nextToken: $nextToken) {
        items {
          company {
            name
            city
            state
            zip_code
            createdAt
            updatedAt
            vip_type
            assignedTo {
              name
            }
          }
        }
        nextToken
      }
    }
  }
`;