export const getPipelines = /* GraphQL */ `
  query ListPipelines(
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPipelines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        phase
        sale_amount
        createdAt
        updatedAt
        project_title
        description
        archived
        closedLostReason
        assignedTo {
          id
          name
        }
        history {
          phase
          createdAt
          editedBy {
            id
            name
          }
          description
        }
        contactId
        searchName
        contact {
          id
          type
          first_name
          last_name
          full_name
          pipeline {
            nextToken
          }
          company {
            id
            name
          }
          assignedTo {
            id
            name
          }
        }
      }
      nextToken
    }
  }
`;

export const getPipelinesByCreatedAt = /* GraphQL */ `
  query ListPipelinesByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPipelinesByCreatedAt(
      createdAt: $createdAt,
      filter: $filter,
      limit: $limit,
      nextToken: $nextToken,
      type: $type,
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        phase
        sale_amount
        createdAt
        updatedAt
        project_title
        description
        archived
        closedLostReason
        assignedTo {
          id
          name
        }
        history {
          phase
          createdAt
          editedBy {
            id
            name
          }
          description
        }
        contactId
        searchName
        contact {
          id
          type
          first_name
          last_name
          full_name
          pipeline {
            nextToken
          }
          company {
            id
            name
          }
          assignedTo {
            id
            name
          }
        }
      }
      nextToken
    }
  }
`;

export const getPipelinesByUpdatedAt = /* GraphQL */ `
  query ListPipelinesByUpdatedAt(
    $type: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPipelinesByUpdatedAt(
      updatedAt: $updatedAt,
      filter: $filter,
      limit: $limit,
      nextToken: $nextToken,
      type: $type,
      sortDirection: $sortDirection
      ) {
      items {
        id
        type
        phase
        sale_amount
        createdAt
        updatedAt
        project_title
        description
        archived
        closedLostReason
        assignedTo {
          id
          name
        }
        history {
          phase
          createdAt
          editedBy {
            id
            name
          }
          description
        }
        contactId
        searchName
        contact {
          id
          type
          first_name
          last_name
          full_name
          pipeline {
            nextToken
          }
          company {
            id
            name
          }
          assignedTo {
            id
            name
          }
        }
      }
      nextToken
    }
  }
`;

export const getPipelinesByEmployee = /* GraphQL */ `
  query ListPipelinesByEmployee(
    $type: String
    $employeeId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPipelinesByEmployee(
      type: $type
      employeeId: $employeeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        phase
        sale_amount
        createdAt
        updatedAt
        project_title
        description
        archived
        closedLostReason
        assignedTo {
          id
          name
        }
        history {
          phase
          createdAt
          editedBy {
            id
            name
          }
          description
        }
        contactId
        employeeId
        searchName
        contact {
          id
          type
          first_name
          last_name
          full_name
          pipeline {
            nextToken
          }
          company {
            id
            name
          }
          assignedTo {
            id
            name
          }
        }
      }
      nextToken
    }
  }
`;

export const getPipelinesBySearchName = /* GraphQL */ `
  query ListPipelinesBySearchName(
    $type: String
    $searchName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPipelinesBySearchName(
      type: $type
      searchName: $searchName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        phase
        sale_amount
        createdAt
        updatedAt
        project_title
        description
        archived
        closedLostReason
        assignedTo {
          id
          name
        }
        history {
          phase
          createdAt
          editedBy {
            id
            name
          }
          description
        }
        contactId
        searchName
        contact {
          id
          type
          first_name
          last_name
          full_name
          pipeline {
            nextToken
          }
          company {
            id
            name
          }
          assignedTo {
            id
            name
          }
        }
      }
      nextToken
    }
  }
`;

export const getPipelinesByContact = /* GraphQL */ `
  query ListPipelinesByContact(
    $type: String
    $contactId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPipelinesByContact(
      type: $type
      contactId: $contactId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        phase
        sale_amount
        createdAt
        updatedAt
        project_title
        description
        archived
        closedLostReason
        assignedTo {
          id
          name
        }
        history {
          phase
          createdAt
          editedBy {
            id
            name
          }
          description
        }
        contactId
        employeeId
        searchName
        contact {
          id
          type
          first_name
          last_name
          full_name
          pipeline {
            nextToken
          }
          company {
            id
            name
          }
          assignedTo {
            id
            name
          }
        }
      }
      nextToken
    }
  }
`;

export const getPipelinesByPhase = /* GraphQL */ `
  query ListPipelinesByPhase(
    $type: String
    $phase: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPipelinesByPhase(
      type: $type
      phase: $phase
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        phase
        sale_amount
        createdAt
        updatedAt
        project_title
        description
        archived
        closedLostReason
        assignedTo {
          id
          name
        }
        history {
          phase
          createdAt
          editedBy {
            id
            name
          }
          description
        }
        contactId
        searchName
        contact {
          id
          type
          first_name
          last_name
          full_name
          pipeline {
            nextToken
          }
          company {
            id
            name
          }
          assignedTo {
            id
            name
          }
        }
      }
      nextToken
    }
  }
`;

export const getPipelineDetails = /* GraphQL */ `
  query GetPipeline($id: ID!) {
    getPipeline(id: $id) {
      id
      type
      phase
      sale_amount
      createdAt
      updatedAt
      project_title
      description
      history {
        phase
        createdAt
        editedBy {
          id
          name
        }
        description
      }
      contactId
      employeeId
      searchName
      archived
      closedLostReason
      sendNotification
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
      }
      assignedTo {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;