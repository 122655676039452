import * as mutations from "../../src";
import { API, graphqlOperation} from "aws-amplify";
import { createAction } from "redux-actions";
import snakeCase from "lodash/fp/snakeCase";
import toUpper from "lodash/fp/toUpper";
import mapValues from "lodash/mapValues";
import flow from "lodash/flow";

const toUpperSnakeCase = flow([snakeCase, toUpper]);

const amplifyActions = mapValues({ ...mutations }, (operation, name) => {
    const actionName = toUpperSnakeCase(name);
    const actionCreator = async input => {
      const { data } = await API.graphql(graphqlOperation(operation, input));
      return data[name] || data;
    };
  
    return createAction(actionName, actionCreator);
});
  
export default amplifyActions;