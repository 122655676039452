export const useGetDivision = /* GraphQL */ `
  query GetDivision($id: ID!, $contactsNextToken: String, $companiesNextToken: String) {
    getDivision(id: $id) {
      id
      label
      contacts(nextToken: $contactsNextToken) {
        items {
          id
          division {
            id
            label
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      companies(nextToken: $companiesNextToken) {
        items {
          id
          division {
            id
            label
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const useGetDivisionWithContacts = /* GraphQL */ `
  query GetDivision($id: ID!, $contactsLimit: Int, $contactsNextToken: String,) {
    getDivision(id: $id) {
      id
      label
      contacts(limit: $contactsLimit, nextToken: $contactsNextToken) {
        items {
          id
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
            company {
              id
              name
            }
            groups {
              items {
                id
                group {
                  id
                  name
                  type
                }
              }
            }
            divisions {
              items {
                id
                division {
                  id
                  label
                }
              }
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const useGetDivisionWithCompanies = /* GraphQL */ `
  query GetDivision($id: ID!, $companiesLimit: Int, $companiesNextToken: String,) {
    getDivision(id: $id) {
      id
      label
      companies(limit: $companiesLimit, nextToken: $companiesNextToken) {
        items {
          id
          company {
            id
            name
            vip
            vip_type
            city
            state
            zip_code
            createdAt
            updatedAt
            assignedTo {
              id
              name
            }
            contacts {
              items {
                id
              }
            }
            groups {
              items {
                id
                group {
                  id
                  name
                  type
                }
              }
            }
            divisions {
              items {
                id
                division {
                  id
                  label
                }
              }
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;