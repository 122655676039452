export const getCollectionWithProducts = /* GraphQL */ `
    query getCollection($id: ID!){
        getCollection(id: $id){
            id
            name
            products (sortDirection: ASC, limit: 1000) {
                items {
                    id
                    productName
                    collectionId
                    product {
                        id
                        name
                        year
                        thumbnail {
                            id
                            name
                            url
                            index
                        }
                    }
                }
            }
        }
    }
`;