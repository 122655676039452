/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://xlk7yrn7rffnlprtbxshnowaya.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-fnfroqirbfbldntxod2gryj5zu",
    "aws_cloud_logic_custom": [
        {
            "name": "gmailAuthorizeAPI",
            "endpoint": "https://c1nfcu8ntd.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "gmailEndpointsAPI",
            "endpoint": "https://ix51ov51og.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "googleAnalyticsAPI",
            "endpoint": "https://82fb69nveg.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "googleCalendarAuthorizeAPI",
            "endpoint": "https://a0c6q6ku6d.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "googleCalendarEndpointsAPI",
            "endpoint": "https://3otllx1120.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "mailchimpAuthorizeAPI",
            "endpoint": "https://bxury6fyqh.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "mailchimpEndpointsAPI",
            "endpoint": "https://edeizhpehi.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "mailchimpSyncAPI",
            "endpoint": "https://5b5wc0tcpf.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "mailchimpWebhookAPI",
            "endpoint": "https://31yv1bk2p8.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:8cd654d7-5344-4417-8523-f73692c2050e",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_A8DCO2scA",
    "aws_user_pools_web_client_id": "6ntosoojff1rnv6qb5snvttbbe",
    "oauth": {
        "domain": "panafoldcmsff7d7f48-ff7d7f48-production.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3030/",
        "redirectSignOut": "http://localhost:3030/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "panafold-connect-storageproduction-production",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
