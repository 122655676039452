import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const FormDialog = ({
  cancelLabel,
  onCancel,
  onSubmit,
  open,
  id,
  Form,
  submitLabel,
  title,
  maxWidth,
  fullWidth,
  disableButton,
  ...props
}) => {
  return (
    <Dialog open={open} maxWidth={maxWidth} fullWidth={fullWidth}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Form onSubmit={onSubmit} id={id} {...props} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" width={136} onClick={onCancel} disabled={disableButton}>
          {cancelLabel}
        </Button>
        <Button
          variant="contained"
          color="primary"
          width={136}
          type="submit"
          form={id}
          disabled={disableButton}
        >
          {submitLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FormDialog.defaultProps = {
  submitLabel: "Save",
  cancelLabel: "Cancel"
};

export default FormDialog;
